<template>
  <div id="register">
    <section class="hero is-medium">
      <div class="hero-body">
        <loading v-if="loading">
          <h2 class="subtitle">{{ prompt }}</h2>
        </loading>
        <div class="columns is-multiline is-centered" v-else>
          <div class="column is-12">
            <simsed-logo width="48" font-size="1.5em" />
            <h1 class="has-text-centered">
              School information management application
            </h1>
          </div>
          <div class="column" v-if="created">
            <div class="has-text-success has-text-centered">
              <p>
                <fa-icon icon="check-circle" class="fa-5x" />
              </p>
              <p class="title is-3">Welcome!!!</p>
              <p class="subtitle is-6">
                Check your email for the login details.
              </p>
              <router-link to="/log_in"> Login </router-link>
            </div>
          </div>
          <div class="column is-6-tablet is-4-desktop" v-else>
            <ValidationObserver ref="observer" v-slot="{ passes }">
              <form @submit.prevent="passes(create)">
                <template>
                  <section>
                    <SdInput
                      type="text"
                      rules="required"
                      label="School Name"
                      v-model="school.name"
                      placeholder="Enter a name"
                    />

                    <SdInput
                      type="text"
                      rules="required|email"
                      label="Email"
                      v-model="school.email"
                      placeholder="Enter email"
                    />

                    <div class="field is-grouped is-grouped-right mt-4">
                      <button type="submit" class="button is-success">
                        Register
                      </button>
                    </div>
                  </section>
                </template>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '../components/SdInput'
import { ValidationObserver } from 'vee-validate'
import SimsedLogo from '../components/SimsedLogo'
import { crudNotification } from '../assets/js/notification'

export default {
  name: 'register',
  data() {
    return {
      pageTitle: 'Register',
      loading: false,
      school: {
        name: null,
        email: null,
      },
      created: false,
      prompt: 'Creating School...',
    }
  },
  methods: {
    create() {
      this.loading = true
      const name = this.school.name
      const email = this.school.email

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation createSchoolQuery($name: String!, $email: String!) {
              createSchool(input: { name: $name, email: $email }) {
                school {
                  id
                  name
                  email
                }
                errors
              }
            }
          `,
          // Parameters
          variables: {
            name: name,
            email: email,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.createSchool.errors,
            'Successfully created.'
          )
            .then(() => {
              this.prompt = 'School Created...'
              setTimeout(() => {
                this.$router.push('/log_in')
              }, 2000)
            })
            .catch((error) => {
              this.loading = false
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
  components: {
    SdInput,
    ValidationObserver,
    SimsedLogo,
  },
}
</script>

<style scoped>
#register {
  text-align: left;
}
</style>
